.title {
  padding: 1rem;
}
.title span {
  color: #FFFFFFFF;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;
  font-size: 3rem;
  transition: 1s;
  cursor: default;
}

.title:hover span {
  color: red;
}

span {
  text-align: center;
}
@media(max-width: 480px) {
  .title span {
    font-size: 2rem;
  }
}