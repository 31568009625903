.titleLogo {
  position: absolute;
  top: 25%;
  left: 35%;
  font-size: 15rem;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: rgba(33, 62, 112, 0.514);
  cursor: default;
}

@media(max-width: 700px) {
  .titleLogo {
    font-size: 13rem;
    top: 27%;
    left: 30%;
  }
}

@media(max-width: 680px) {
  .titleLogo {
    left: 32%;
  }
}
@media(max-width: 480px) {
  .titleLogo {
    font-size: 11rem;
  }
}
@media(max-width: 400px) {
  .titleLogo {
    left: 20%;
    font-size: 9rem;
  }
}