.title {
  display: inline-block;
}

.title a {
  text-decoration: none;
  color: #FFFFFFFF;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;
  font-size: 2rem;
  transition: 1s;
}

.titleOverflow {
  position: absolute;
  top: 23%;
  right: 46.5%;
  color: white;
  opacity: 0;
  transition: 0.5s;
  cursor: default;
}

.title:hover a {
  color: red;
}

.title:hover .titleOverflow {
  opacity: 1;
  right: 45.5%;
}
