.list {
  display: flex;
  gap: 2rem;
  list-style: none;
}

.listItem a {
  position: relative;
  cursor: pointer;
  font-size: 1.1rem;
  text-decoration: none;
  color: #B0B2C3FF;
  transition: 0.5s;
}

.listItem:hover a {
  color: white;
}

.listItem a:after {
  display: block;
  position: absolute;
  right: 0;
  bottom: -3px;
  width: 0;
  height: 3px;
  background-color: rgb(8, 4, 245);
  content: "";
  transition: width 0.25s cubic-bezier(.694, .048, .335, 1);
}

.listItem a:hover:after {
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  bottom: -3px;
  height: 3px;
  background-color: rgb(255, 1, 1);
  content: "";
  transition: width 0.15s cubic-bezier(.694, .048, .335, 1);
}

@media(max-width: 768px) {
  .list {
    margin: 0;
    gap: 1;
    padding: 1rem;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.267);
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 100%;
    right: 0;
  }
  .listItem a {
    color: white;
  }
}